<template>
  <!-- <v-container
    class="main-img"
    fluid
    style="display: flex; justify-content: center; align-items: center"
  >
    <v-col cols="12" sm="10" md="8" lg="8"> -->
  <v-card elevation="0">
    <v-window v-model="step">
      <!-- Log In Window -->
      <v-window-item :value="1">
        <!-- <v-row>
          <v-col cols="12" sm="6" md="6"> -->
        <v-card-title style="text-align: center !important; display: block">
          <img src="../assets/turologo.png" width="150" height="auto" />
        </v-card-title>
        <v-card-text style="margin-top: 5px; margin-bottom: 15px">
          <h4 class="text-center" style="font-size: 22px; margin-bottom: 10px">
            Welcome to TuroBuddy
          </h4>
          <br />

          <v-form style="padding-right: 15px; padding-left: 15px">
            <v-text-field
              v-model="loginUsername"
              label="Email"
              prepend-icon="email"
              type="text"
              placeholder="Enter Email ID"
            />
            <v-text-field
              v-model="loginPassword"
              prepend-icon="lock"
              placeholder="Enter Password"
              name="password"
              label="Enter password"
              :append-icon="value ? 'visibility' : 'visibility_off'"
              @click:append="() => (value = !value)"
              :type="value ? 'password' : 'text'"
            />
          </v-form>

          <div class="text-center mt-3">
            <v-btn rounded dark @click.prevent="login">SIGN IN</v-btn>
          </div>

          <hr style="opacity: 0; margin: 20px 50px" />
          <h4 class="text-center" style="margin: 10px">Continue With</h4>
          <div class="text-center">
            <v-btn
              style="margin: 5px"
              fab
              color="primary"
              small
              to="https://www.facebook.com/"
            >
              <v-icon>fab fa-facebook-f</v-icon>
            </v-btn>
            <v-btn style="margin: 5px" fab small to="https://www.google.com/">
              <v-icon>fab fa-google-plus-g</v-icon>
            </v-btn>
            <v-btn
              style="margin: 5px"
              fab
              color="#3700B3"
              small
              to="https://www.linkedin.com/"
            >
              <v-icon color="white">fab fa-linkedin-in</v-icon>
            </v-btn>
          </div>

          <div style="text-align: center">
            <p @click="forgotpasswordf()"><b>forgot password</b></p>
            <div v-if="forgotpassword">
              <v-row>
                <v-col cols="8">
                  <v-text-field
                    id="forgotinputEmail"
                    v-model="forgotusername"
                    label="write here your email "
                    name="Email"
                    prepend-icon="email"
                    type="text"
                  />
                </v-col>
                <v-col cols="4">
                  <div style="padding-top: 20px">
                    <v-btn @click="fogotsubmit()" text>submit</v-btn>
                  </div>
                </v-col>
              </v-row>
            </div>

            <h3
              class="text-center mt-4"
              @click="step++"
              style="cursor: pointer"
            >
              Create an account
            </h3>
          </div>
        </v-card-text>
        <!-- </v-col>
          <v-col cols="12" md="6" sm="6" class="bgimg">
                <v-card-text style="margin-top: 25px">
                  <h1 class="text-center display-1">Hello, Friend!</h1>
                  <h5
                    class="text-center"
                    style="
                      margin-bottom: 25px;
                      margin-top: 10px;
                      font-size: 15px;
                      opacity: 0.6;
                    "
                  >
                    Enter your personal details and start journey with us
                  </h5>
                </v-card-text>
              </v-col>
        </v-row> -->
      </v-window-item>
      <!-- Sign Up Window -->
      <v-window-item :value="2">
        <!-- <v-row class="fill-height">
          <v-col cols="12" md="6" sm="6" class="bgimg">
            <v-card-text style="margin-top: 25px">
              <h1 class="text-center display-1">Welcome Back!</h1>
              <h5
                class="text-center"
                style="
                  margin-bottom: 25px;
                  margin-top: 10px;
                  font-size: 15px;
                  opacity: 0.6;
                "
              >
                To Keep connected with us please login with your personnel
                information
              </h5>
            </v-card-text>
          </v-col>
          <v-col cols="12" sm="6" md="6"> -->
        <v-card-text>
          <h4
            class="text-center"
            style="font-size: 25px; margin-top: 15px; margin-bottom: 10px"
          >
            Create Account
          </h4>
          <h4 class="text-center mt-4">Ensure your email for registration</h4>
          <v-form style="padding-right: 15px; padding-left: 15px">
            <v-text-field
              id="inputUserame"
              v-model="name"
              label="Name"
              name="Name"
              prepend-icon="person"
              type="text"
            />
            <v-text-field
              id="inputEmail"
              v-model="username"
              label="Email"
              name="Email"
              prepend-icon="email"
              type="text"
            />
            <v-row>
              <v-col
                cols="3"
                style="
                  display: flex;
                  justify-content: center;
                  align-items: center;
                "
              >
                <vue-country-code
                  style="position"
                  @onSelect="onSelect"
                  v-model="mobileCode"
                  :preferredCountries="['vn', 'us', 'gb']"
                >
                </vue-country-code>
              </v-col>
              <v-col cols="9">
                <v-text-field
                  v-model="mobile"
                  type="number"
                  prepend-icon="phone"
                  label="Phone Number"
                  required
                ></v-text-field>
              </v-col>
            </v-row>

            <v-text-field
              id="inputPassword"
              v-model="password"
              label="Password"
              name="password"
              prepend-icon="lock"
              :append-icon="value ? 'visibility' : 'visibility_off'"
              @click:append="() => (value = !value)"
              :type="value ? 'text' :'password'"
            />
            <br />
            <div class="text-center mt-n5">
              <v-btn @click.prevent="register()" rounded dark>SIGN UP</v-btn>
            </div>
          </v-form>
          <hr style="opacity: 0; margin: 20px 50px" />
          <div class="text-center">
            <v-btn
              style="margin: 5px"
              fab
              color="primary"
              small
              to="https://www.facebook.com/"
            >
              <v-icon>fab fa-facebook-f</v-icon>
            </v-btn>
            <v-btn style="margin: 5px" fab small to="https://www.google.com/">
              <v-icon>fab fa-google-plus-g</v-icon>
            </v-btn>
            <v-btn
              style="margin: 5px"
              fab
              color="#3700B3"
              small
              to="https://www.linkedin.com/"
            >
              <v-icon color="white">fab fa-linkedin-in</v-icon>
            </v-btn>
          </div>
          <div style="text-align: center">
            <h3
              class="text-center mt-4"
              @click="step++"
              style="cursor: pointer; margin-bottom: 15px"
            >
              Already registered? Login
            </h3>
          </div>
        </v-card-text>
        <!-- </v-col>
        </v-row> -->
      </v-window-item>
    </v-window>

    <v-dialog v-model="loading" hide-overlay persistent width="300">
      <v-card color="primary" dark>
        <v-card-text style="padding: 10px">
          Please stand by
          <v-progress-linear
            indeterminate
            color="white"
            class="mb-0"
          ></v-progress-linear>
        </v-card-text>
      </v-card>
    </v-dialog>
  </v-card>
  <!-- </v-col>
  </v-container> -->
</template>
<script>
import { required, digits, max, regex } from 'vee-validate/dist/rules';
// import EventBus from '../../utils/EventBus';
import { extend, setInteractionMode } from 'vee-validate';
import msgHelper from '../../utils/msg-helper';
import { mapState } from 'vuex';
setInteractionMode('eager');
extend('digits', {
  ...digits,
  message: '{_field_} needs to be {length} digits. ({_value_})',
});

extend('required', {
  ...required,
  message: '{_field_} can not be empty',
});

extend('max', {
  ...max,
  message: '{_field_} may not be greater than {length} characters',
});

extend('regex', {
  ...regex,
  message: '{_field_} {_value_} does not match {regex}',
});
export default {
  components: {},
  data: () => {
    return {
      forgotusername: '',
      forgotpassword: false,
      loading: false,
      value: String,
      step: 1,
      loginUsername: '',
      loginPassword: '',
      username: '',
      password: '',
      mobile: '',
      mobileCode: '',
      name: '',
    };
  },
  computed: {
    ...mapState(['packageId']),
  },
  mounted() {
    document.title = 'TuroBuddy | Customer Login or SignUp';
  },
  methods: {
    fogotsubmit() {
      if (this.forgotusername === '') {
        msgHelper.showToast('warning', 'Please enter your Email id');
      } else {
        this.loading = true;
        this.$http
          .post('forgot-password', {
            email: this.forgotusername,
          })
          .then((res) => {
            console.log(res);
            msgHelper.showToast(
              'success',
              `Check your email, we send the reset password link`
            );
            //this.$emit('dismissDialog');
            // this.$router.go(0);
            this.loading = false;
          })
          .catch((err) => {
            console.log(err);
            // if (err.response) {
            //   msgHelper.showToast('error', err.response.data.message);
            // } else {
            //   msgHelper.showToast(
            //     'error',
            //     'Something went wrong. Please try again later'
            //   );
            // }
            this.loading = false;
          });

        this.forgotpassword = false;
      }
      // this.forgotpassword = false;
    },
    forgotpasswordf() {
      this.forgotpassword = true;
    },
    onSelect({ dialCode }) {
      this.mobileCode = dialCode;
    },
    // register an customer
    register() {
      if (this.name === '') {
        msgHelper.showToast('warning', 'Please enter your Account Name');
      } else if (this.username === '') {
        msgHelper.showToast('warning', 'Please enter the Email ID');
      } else if (
        !/^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/.test(
          this.username
        )
      ) {
        msgHelper.showToast('warning', 'Please enter valid Email ID');
      } else if (this.password === '') {
        msgHelper.showToast(
          'warning',
          'Please choose a Password of your Account'
        );
      } else if (
        !/^(?=.*[A-Za-z])(?=.*\d)(?=.*[@$!%*#?&])[A-Za-z\d@$!%*#?&]{8,}$/.test(
          this.password
        )
      ) {
        msgHelper.showToast(
          'warning',
          'Please enter valid password Minimum eight characters, at least one letter, one number and one special character'
        );
      } else if (this.mobile === '') {
        msgHelper.showToast('warning', 'Please enter the Mobile Number');
      } else {
        (async () => {
          this.loading = true;
          try {
            await this.$http.post('createAccountByEmail', {
              role_id: 3,
              username: this.username,
              password: this.password,
              mobile: '+' + this.mobileCode + this.mobile,
              name: this.name,
              access_type: 1,
              master_admin_id: 'Zreyas',
            });
            msgHelper.showToast(
              'success',
              'Your TuroBuddy account has been created successfully'
            );
            // this.$router.replace({ name: 'Dashboard' });
            this.loginUsername = this.username;
            this.loginPassword = this.password;
            await this.login();
            // Reset to original form
            this.username = '';
            this.password = '';
            this.mobile = '';
            this.mobileCode = '';
            this.name = '';
          } catch (err) {
            console.log(err);
            if (err.response) {
              msgHelper.showToast('error', err.response.data.message);
            } else {
              msgHelper.showToast(
                'error',
                'Something went wrong, please try again later'
              );
            }
          }
          this.loading = false;
        })();
      }
    },
    // Login using email and password
    login() {
      if (this.loginUsername === '') {
        return msgHelper.showToast('warning', 'Please enter valid Email ID');
      } else if (this.loginPassword === '') {
        return msgHelper.showToast('warning', 'Please enter valid Password');
      } else if (
        !/^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/.test(
          this.loginUsername
        )
      ) {
        return msgHelper.showToast('warning', 'Please enter valid Email ID');
      } else {
        this.loading = true;
        this.$http
          .post('loginCustomerUsingEmail', {
            username: this.loginUsername,
            password: this.loginPassword,
          })
          .then((res) => {
            this.$store.commit('setUser', res.data.user);
            this.$store.commit('setToken', res.data.token);
            this.$http.defaults.headers.common[
              'Authorization'
            ] = `Bearer ${res.data.token}`;
            /* const packageId = this.packageId;
          if (
            packageId &&
            (typeof packageId !== 'undefined' || packageId !== '')
          ) {
            this.$router.replace({
              name: 'Payment',
              params: {
                packageId,
              },
            });
          } else {
            this.$router.replace({ name: 'Dashboard' });
          } */
            msgHelper.showToast(
              'success',
              `Welcome ${res.data.user.name}, you are logged in successfully`
            );
            this.$emit('dismissDialog');
            this.$router.go(0);
            this.loading = false;
          })
          .catch((err) => {
            console.log(err);
            if (err.response) {
              msgHelper.showToast('error', err.response.data.message);
            } else {
              msgHelper.showToast(
                'error',
                'Something went wrong. Please try again later'
              );
            }
            this.loading = false;
          });
      }
    },
  },
};
</script>

<style scoped src="../assets/css/style.css"></style>
